export class Stats {
  active: number;
  received: number;
  attention: number;
  closed: number;
  pipeline: number;
  won: number;
  dispositioned: Array<any>;
  messages: number;
  calls: number;
  facebook: number;
  leadflow: Array<any>;
  activity: Array<any>;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
